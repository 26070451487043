<template>
  <div class="ordersearch">
    <van-sticky>
      <van-search
        v-model="searText"
        placeholder="请输入搜索关键词"
        shape="round"
        show-action
        @focus="inputFocus"
        @blur="inputBlur"
        @input="inputText"
        ref="search"
        @search="onSearch"
      >
        <template #left>
          <van-dropdown-menu>
            <van-dropdown-item v-model="option1" :options="actionList" />
          </van-dropdown-menu>
        </template>
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
    </van-sticky>
    <div class="main" v-if="list.length > 0 ? true: false">
      <van-list
        v-model="loading"
        :finished="finished"
        :immediate-check="false"
        finished-text="没有更多了"
        @load="onLoad()"
      >
        <div class="order_list" v-for="(item, index) in list" :key="index">
          <van-cell :border="false">
            <template #title>
              {{item.ordersn}}
              <input type="text" v-model="item.ordersn" v-show="false" />
              <van-button
                v-clipboard:copy="item.ordersn"
                v-clipboard:success="ordersnonCopy"
                v-clipboard:error="ordersnError"
                size="mini"
              >
                复制
              </van-button>
            </template>
            <template #right-icon>
              <span class="red">{{ item.status_str }}</span>
            </template>
          </van-cell>
          <div v-if="item.goods && item.goods.length > 0">
            <van-card
              v-for="(v,k) in item.goods"
              :key="k"
              :num="v.total"
              :price="v.realprice"
              :title="v.goodstitle"
              :thumb="v.goodsthumb"
              @click="details(index, k)"
              lazy-load
            >
              <template #desc>
                <div>
                  {{v.optionname}}
                  <van-tag color="#9168ed" text-color="#fff" v-if="v.yushouTime">{{v.yushouTime}}</van-tag>
                </div>
              </template>
              <template #tag>
                {{strId}}
              </template>
            </van-card>
          </div>
          <van-cell title="">
            <template #right-icon>实付：&yen;{{item.price}}({{ item.dispatchprice > 0 ? "运费：" + item.dispatchprice + "元" : "免运费" }})</template>
          </van-cell>
          <van-cell title>
            <template #right-icon>
              <van-button
                size="small"
                plain
                hairline
                @click="reFundHistory(item.id)"
                v-if="item.bc_list_btn == 1"
              >退款记录</van-button>
              <van-button
                size="small"
                plain
                hairline
                @click="miniTuikuan(item.id)"
                v-if="item.bc_go_btn == 1"
              >申请退款</van-button>
              <van-button
                size="small"
                v-if="item.bc_go_btn == 2"
                plain
                hairline
                @click="reFund(index)"
              >{{item.is_saleservice > 0 && item.is_saleservice != 2 ? '售后中' : '申请售后'}}</van-button>
              <van-button
                size="small"
                v-if="item.is_service_btn"
                plain
                hairline
                @click="reFund(index)"
              >{{item.is_service_btn}}</van-button>
              <van-button size="small" v-if="item.is_service_btn2" plain hairline @click="cancelRefund(index)">{{item.is_service_btn2}}</van-button>
              <van-button size="small" type="danger" plain hairline @click="toGoods(item.goods[0].goodsid, index)">再来一单</van-button>
              <van-button v-if="item.status > 0 && item.status < 2" @click="cuidan(item.ordersn)" size="small" plain hairline>催单</van-button>
              <van-button size="small" v-if="item.status == 0" @click="toPay(item.id, index)">去支付</van-button>
              <van-button size="small" v-if="item.status == 0" @click="cancelPay(item.id, index)">关闭订单</van-button>
            </template>
          </van-cell>
          <van-cell :border="false"  v-if="item.is_saleservice_content && item.is_saleservice_content.length > 0" is-link class="shouH_cell" @click="serviceDetail(item.id)">
            <template #title>
              <div class="red">
                售后内容: {{item.is_saleservice_content}}
                <van-tag class="copay_icon" v-if="item.is_saleservice > 0 && item.is_saleservice != 2" plain @click="addserviceInfo(index)" >{{(item.rtype == 2 || item.rtype == 1 || item.rtype == 3) && item.progress == 3 ? '添加单号': '回复'}}</van-tag>
              </div>
            </template>
            <template #default>
              <div>
                查看
              </div>
            </template>
          </van-cell>
          <van-cell :border="false" v-if="item.check_status == 2">
            <template #title>
              <div style="color: #ee0a24;">
                请退款{{item.special_remark ? "：" + item.special_remark : ""}}
              </div>
            </template>
          </van-cell>
          <van-cell-group :border="false" class="address_info">
            <van-cell :border="false">
              <template #title>
                {{item.contact_name ? item.contact_name : ""}}
              </template>
              <template #default>
                {{item.mobile ? item.mobile : ""}}
                <van-button
                  v-clipboard:copy="item.contact_name + ' ' + item.mobile + ' ' + item.province + ' ' + item.city + ' ' + item.county + ' ' + item.street"
                  v-clipboard:success="ordersnonCopy"
                  v-clipboard:error="ordersnError"
                  size="mini"
                >
                  复制
                </van-button>
              </template>
            </van-cell>
            <van-cell :border="false" title title-class="tongchang">
              <template #title>
                {{item.province ? item.province : ""}}
                {{item.city ? item.city : ""}}
                {{item.county ? item.county : ""}}
                {{item.street ? item.street : item.address}}
              </template>
            </van-cell>
          </van-cell-group>
          <div class="kefu_coucst" v-if="merchid == 0 || showkefu == 1">
            <van-button
              block
              icon="service-o"
              @click="qiyukefu(index)"
            >
              联系客服
            </van-button>
          </div>
        </div>
      </van-list>
    </div>
    <div class="main" v-show="shownodata">
      <van-empty description="暂无订单" />
    </div>
    <div id="loadingdiv" :style="'height:' + screeHeight + 'px;'" v-if="loadingdiv">
      <van-loading size="24px" type="spinner" vertical>搜索中...</van-loading>
    </div>
  </div>
</template>
<script>
export default {
  name: "OrderSearch",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      orderactive: 0, // 订单状态
      loading: false,
      finished: false,
      currSize: 10, // 每次查询条数
      currOffset: 0, // 查询到哪里了
      list: [], // 展示内容
      shareid: 0,
      merchid: 0,
      strId: "", // 水印
      showList: false, // 显示搜索类别
      option1: 0,
      actionList: [
        { text: '电话', value: 0 },
        { text: '商品名', value: 1 },
        { text: '订单号', value: 2 },
        { text: '姓名', value: 3 }
      ],
      searText: "", // 搜索关键字
      shownodata: false,
      loadingdiv: false, // 搜索中
      userinfo: {},
      showkefu: 0
    };
  },
  mounted() {
    this.strId = localStorage.getItem("DK_STRID");
    // this.init();
    if (this.$route.query.shareid) {
      this.shareid = this.$route.query.shareid
    }else if (localStorage.getItem("DK_MEMBERID")) {
      this.shareid = localStorage.getItem("DK_MEMBERID");
    }
    this.merchid = this.$route.query.merchid ? this.$route.query.merchid : 0;
    this.$parent.getmerchname(this.merchid, "订单搜索");
    this.$refs['search'].focus();

  },
  activated() {
    this.loading = false;
    this.currOffset = 0;
    setTimeout(() => {
      this.showkefu = localStorage.getItem("DK_SHOWKEFU");
    }, 500);
    
    this.init();
  },
  methods: {
    init() {
      this.getuserinfo();
      this.$refs.search.focus();
    },
    // 获取用户信息
    getuserinfo() {
      let _that = null;
      _that = this;
      console.log("ddddsss");
      _that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("DK_UID");
      _that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      _that.$axios
        .post(_that.$store.state.domain + "web/user/getinfor")
        .then(function(response) {
          if (response.data && response.data.id > 0) {
            _that.userinfo = response.data;
          }
        })
        .catch(function(error) {
          console.log(error);
        });
    },
    getSearch() {
      let _that = null;
      _that = this;
      _that.loadingdiv = true;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_search_order",
          _that.$qs.stringify({
            searType: _that.option1,
            searText: _that.searText,
            size: _that.currSize,
            offset: _that.currOffset,
            merchid: _that.merchid
          })
        )
        .then(response => {
          _that.loading = false;
          _that.loadingdiv = false;
          if (response.data && response.data.code == 100000) {
            _that.shownodata = false;
            _that.list = response.data.data;
            if (response.data.data.length >= _that.currSize) {
              _that.currOffset = _that.currSize;
              _that.finished = false;
            } else {
              _that.currOffset = response.data.data.length;
              _that.finished = true;
            }
          } else {
            _that.finished = true;
            _that.shownodata = true;
            _that.list = [];
          }
        })
        .catch(error => {
          _that.loading = false;
          _that.finished = true;
          _that.shownodata = true;
          console.log(error);
        });
    },
    // 滚动加载
    onLoad() {
      let _that = null;
      _that = this;
      let UID = null;
      UID = localStorage.getItem("DK_UID"); // 用户身份公钥
      _that.$axios.defaults.headers.common["Authorization"] = UID;
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/get_search_order",
          _that.$qs.stringify({
            searType: _that.option1,
            searText: _that.searText,
            size: _that.currSize,
            offset: _that.currOffset,
            merchid: _that.merchid
          })
        )
        .then(response => {
          console.log(response);
          _that.loading = false;
          if (response.data.code == 100000) {
            if (_that.list.length > 0 && _that.currOffset > 0) {
              response.data.data.forEach(item => {
                _that.list.push(item);
              });
            } else {
              _that.list = response.data.data;
            }

            if (response.data.data.length >= _that.currSize) {
              _that.currOffset += _that.currSize;
              _that.finished = false;
            } else {
              _that.currOffset += response.data.data.length;
              _that.finished = true;
            }
          } else {
            _that.finished = true;
          }
        })
        .catch(error => {
          _that.loading = false;
          _that.finished = true;
          console.log(error);
        });
    },
    // 查看订单详情
    details(index) {
      let status = null;
      status = this.list[index].status;
      let id = 0;
      id = this.list[index].id;
      let title = "";
      if (status == 0) {
        title = "待付款";
      } else if (status == 1) {
        title = "待发货";
      } else if (status == 2) {
        title = "待收货";
      } else if (status == 3) {
        title = "已完成";
      } else if(status == 5) {
        title = "驳回";
      } else if(status == 6) {
        title = "售后";
      }

      this.$router.push({
        name: "OrderDetails",
        query: { title: title, status: status, id: id, shareid: this.shareid, merchid: this.merchid, _t: 3 }
      });
    },
    // 去支付
    toPay(id, index) {
      console.log("去支付", id, index, this.list[index]);
      let uid = 0; // 用户id
      let ordersn = ""; // 订单编号
      let money = 0; // 订单金额
      let orderid = 0; // 订单id
      uid = this.list[index].memberid;
      ordersn = this.list[index].ordersn;
      money = this.list[index].price;
      orderid = this.list[index].id;
      localStorage.setItem("ordersn", ordersn);
      localStorage.setItem("money", money);
      localStorage.setItem("orderid", orderid);
      this.$router.push({name: "CheckPayment", query: {shareid: this.shareid, merchid: this.merchid} });
    },
    // 取消订单
    cancelPay(id, index) {
      console.log(id, index);
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "关闭订单",
        message: "确定要关闭订单?",
        showCancelButton: true,
        beforeClose: function(action, done) {
          if (action == "confirm") {
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/cancel",
                _that.$qs.stringify({
                  id: id
                })
              )
              .then(response => {
                console.log(response);
                if (response && response.data && response.data.code == 100000) {
                  console.log("取消订单成功");
                  _that.$toast(response.data.msg);
                  _that.list.splice(index, 1);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
          if (action == "cancel") {
            console.log("取消按钮");
          }
          done();
        }
      });
    },

    // 退款记录
    reFundHistory(id) {
      this.$router.push({
        name: "refundHistory",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 申请退款
    miniTuikuan(id) {
      this.$router.push({
        name: "miniTuikuan",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      });
    },

    // 催单
    cuidan(ordersn) {
      let _that = null;
      _that = this;
      _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
      _that.$axios
        .post(
          _that.$store.state.domain + "web/order/reminderOrder",
          _that.$qs.stringify({
            ordersn: ordersn
          })
        )
        .then(res => {
          console.log(res);
          if (res.data.code == 100001) {
            _that.$toast(res.data.msg ? res.data.msg : '请重新登陆')
          }else {
            _that.$dialog.alert({
              title: res.data.title ? res.data.title : "",
              message: res.data.msg ? res.data.msg : "",
              theme: 'round-button',
            }).then(() => {
              // on close
            });
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    
    // 确认收货
    Receipt(id, index) {
      let _that = null;
      _that = this;
      this.$dialog.alert({
        title: "是否确认收货?",
        message: '请确认已收到商品',
        showCancelButton: true,
        beforeClose(action, done) {
          if (action == "confirm") {
            console.log("点击确认按钮");
            _that.$axios.defaults.headers.common["Authorization"] = localStorage.getItem("DK_UID");
            _that.$axios
              .post(
                _that.$store.state.domain + "web/order/receipt",
                _that.$qs.stringify({
                  id: id
                })
              )
              .then(response => {
                console.log(response);
                if (response.data.code == 100000) {
                  _that.$notify({
                    type: "success",
                    message: response.data.msg ? response.data.msg : "收货成功"
                  });
                  _that.list.splice(index, 1);
                }else {
                  _that.$notify({
                    type: "warning",
                    message: response.data.msg ? response.data.msg : "收货失败"
                  });
                }
              })
              .catch(err => {
                console.log(err);
              })
          }
          if (action == "cancel") {
            console.log("点击取消按钮");
          }
          done();
        }
      });
    },
    // 复制快递单号
    expressnonCopy() {
      this.$toast("复制成功");
    },
    // 快递单号复制失败
    expressnonError() {
      this.$toast("复制失败");
    },
    // 复制订单号
    ordersnonCopy() {
      this.$toast("复制成功");
    },
    // 复制订单号
    ordersnError() {
      this.$toast("复制失败");
    },

    // 输入框获取焦点
    inputFocus(){
      console.log("val");
    },
    // 输入框失去焦点
    inputBlur(){
      console.log("val");
      if (this.searText == "") {
        // this.$router.go(-1);
      }
    },
    // 获取输入框内容
    inputText(val) {
      this.searText = val;
    },
    // 执行搜索
    onSearch(val) {
      if (val) {
        this.goods = [];
        this.currOffset = 0;
        this.getSearch();
      }
    },
    // 再来一单
    toGoods(gid, index) {
      this.$router.push({name: "Details", query: { id: gid, shareid: this.shareid, merchid: this.merchid, _t: 3 } });
    },
    // 申请退款按钮
    reFund(index) {
      if ((this.list[index].status==1 && (this.list[index]["is_outorder"] == 0 || this.list[index]["is_outorder"] == 3)) || this.list[index]["check_status"] == 2) {
        this.$router.push({
          name: "OrderRefund",
          query: {
            id: this.list[index].id,
            money: this.list[index].price,
            status: this.list[index].status,
            shareid: this.shareid,
            merchid: this.merchid
          } 
        });
      }else {

        if (this.list[index]["is_saleservice"] > 0 && this.list[index]["is_saleservice"] != 2) {
          this.$router.push({
            name: "serviceDetail",
            query: {
              id: this.list[index].id,
              shareid: this.shareid,
              merchid: this.merchid
            } 
          });
        }else {
          if(this.list[index]["check_status"] == 2) {
            this.$router.push({
              name: "miniTuikuan",
              query: {
                id: this.list[index].id,
                shareid: this.shareid,
                merchid: this.merchid
              }
            })
          }else {
            this.$router.push({
              name: "RefundOrderOne",
              query: {
                id: this.list[index].id,
                shareid: this.shareid,
                merchid: this.merchid
              } 
            });
          }
        }
        
      }
      
    },
    addserviceInfo(index) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: this.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        } 
      });
    },
    // 撤销退款
    cancelRefund(index) {
      let _that = null;
      _that = this;
      // _that.$axios
      //   .post(
      //     _that.$store.state.domain + "web/order/cancel_refund",
      //     _that.$qs.stringify({
      //       orderid: _that.list[index].id,
      //       refundid: _that.list[index].refundid
      //     })
      //   )
      //   .then(res => {
      //     console.log(res);
      //     if (res.data.code == 100000) {
      //       _that.$toast(res.data.msg ? res.data.msg : "撤销成功");
      //       _that.$router.go(0); // 刷新当前页
      //     }else{
      //       _that.$toast(res.data.msg ? res.data.msg : "撤销失败");
      //     }
      //   })
      //   .catch(error => {
      //     console.log(error);
      //   })
      _that.$router.push({
        name: "serviceDetail",
        query: {
          id: _that.list[index].id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    // 跳转到售后详情页
    serviceDetail(id) {
      this.$router.push({
        name: "serviceDetail",
        query: {
          id: id,
          shareid: this.shareid,
          merchid: this.merchid
        }
      })
    },
    // 七鱼客服
    qiyukefu(index) {
      let _that = null;
      _that = this;
      let order = {};
      order = _that.list[index];
      console.log(order);

      if ((_that.merchid == 0 || this.showkefu == 1)) {
        let uid = 0;
        let name = "未登录—游客";
        let agentCode = "";
        let level = 1;
        let avatar = "";
        let levelname = "普通会员";
        let mobile = "";
        if (_that.userinfo && _that.userinfo.id > 0) {
          if (_that.userinfo.level == '6') {
            level = 2;
          }else if (_that.userinfo.level == '1') {
            level = 3;
          }else if (_that.userinfo.level == '14') {
            level = 4;
          }else if (_that.userinfo.level == '5') {
            level = 5;
          }
          uid = _that.userinfo.id;
          name = _that.userinfo.nickname;
          agentCode = _that.userinfo.agent_code;
          avatar = _that.userinfo.avatar;
          if (_that.userinfo.levelname) {
            levelname = _that.userinfo.levelname;
          }
          mobile = _that.userinfo.mobile;
        }else{
          _that.$toast("请先登录");
          return false;
        }

        if(_that.userinfo.id > 0) {
          let str = "";
          let desc = "";
          str = JSON.stringify([
            {"index":0, "key":"avatar","label":"头像","value":avatar},
            {"index":1, "key":"agent_code", "label":"邀请码", "value":agentCode},
            {"index":2, "key":"level", "label":"等级", "value":levelname},
            {"index":3, "key":"shopname", "label":"商户名称", "value":localStorage.getItem("DK_SHOPNAME")},
            {"index":4, "key":"mobile", "label":"手机号", "value":mobile},
            {"index":5, "key":"shopid", "label":"商户id", "value":_that.merchid},
            {"index":6, "key":"shareid", "label":"分享者id", "value":_that.shareid}
          ]);
          desc = JSON.stringify([{title: order.ordersn + "【" + order.surplus.time_str + "】"},{picture: order.goods[0].goodsthumb}]);
          window.location.href = "https://kefu.midbest.cn/wap/?uid=" + _that.userinfo.id + "&sessionform=" + str + "&card=" + desc + "&fromType=2";
          return false;
        }
      }else{
        this.$toast("页面还未加载完，稍等一下");
      }
    }
  }
};
</script>
<style lang="less">
.ordersearch {
  background-color: #ededed;
  width: 100%;
  min-height: 100%;
  padding-bottom: 60px;
  .van-search {
    padding: 5px 0;
    .van-dropdown-menu__item{
      width: 65px;
    }
    .van-search__content {
      margin-left: 10px;
      .van-cell {
        .van-cell__value {
          .van-field__body {
            .van-icon {
              margin-right: 10px;
            }
          }
        }
      }
    }
    .van-dropdown-menu__title{
      font-size: 13px;
    }
    .van-cell {
      padding: 10px;
      .van-cell__title {
        flex: 1;
      }
    }
    .van-cell--borderless {
      padding: 0;
      .van-cell__value{
        flex: 1;
        line-height: 40px;
      }
      .van-field__left-icon{
        line-height: 40px;
      }
    }
  }
  .main {
    .order_list {
      margin-top: 10px;
      .van-cell {
        padding: 10px;
        .van-cell__title {
          flex: 1;
          text-align: left;
        }
        .van-button {
          margin-left: 10px;
        }
      }
      .van-cell::after {
        left: 0;
        right: 0;
      }
      .van-card {
        padding: 10px;
        text-align: left;
        margin-top: 0;
        background-color: #fff;
        .van-card__thumb img {
          border-radius: 4px;
        }
        .van-card__tag {
          top: inherit;
          left: inherit;
          bottom: 0;
          right: 0;
          background-color: #fff;
          color: #808080;
          font-size: 14px;
          line-height: 16px;
          padding: 0 2px;
          opacity: 0.5;
        }
      }
      .address_info {
        padding: 0;
        overflow: hidden;
        .van-cell__title{
          flex: none;
          padding-right: 10px;
        }
        .van-cell__value{
          flex: 1;
          text-align: left;
          color: #333;
        }
        .van-cell {
          &:first-of-type {
            padding-bottom: 0;
          }
          .van-cell__label{
            color: #333;
          }
        }
        .tongchang {
          flex: 1;
        }
      }
    }
    
    .red {
      color: #ee0a24;
    }
  }

  // 加载中
  #loadingdiv {
    background-color: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    .van-loading {
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -50px 0 0 -50px;
      padding: 10px 20px;
      border-radius: 6px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      .van-loading__text{
        color: #fff;
      }
    }
  }
}
</style>